import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import SEO from '../components/SEO';
import ContactFormCom from '../components/ContactFormCom';
import SocialMenu from '../components/SocialMenu';

const StyledWrapper = styled.div`
  display: grid;
  font-weight: bold;
  padding: 2rem 4rem 4rem 4rem;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.s};

    column-gap: 50px;
    grid-template-columns: 300px 300px;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
`;

const ColorWrapper = styled.div`
  background-color: #f5f5f5;
`;

const CompanyName = styled.div`
  font-weight: 700;
  padding: 4rem 4rem 0 4rem;
  font-size: ${({ theme }) => theme.font.size.s};

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.m};
    text-align: center;
  }
`;

const SmallerP = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxs};
`;

const WrapperAlignRight = styled.div`
  ${({ theme }) => theme.mq.tablet} {
    text-align: right;
  }
`;
const WrapperAlignLeft = styled.div`
  ${({ theme }) => theme.mq.tablet} {
    text-align: left;
  }
`;

export default function ContactPage({ data }) {
  const submitRequest = async (values, actions) => {
    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/contactForm`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: values.name,
          email: values.email,
          tel: values.tel,
          message: values.message,
          mapleSyrup: values.mapleSyrup,
        }),
      }
    );
    const text = JSON.parse(await res.text());

    actions.setSubmitting(false);

    if (res.status >= 400 && res.status < 600) {
      actions.setStatus({
        sent: false,
        msg: text.message,
      });
    } else {
      actions.resetForm();
      actions.setStatus({
        sent: true,
        msg: `Wiadomość została wysłana. Dziękujemy!`,
      });
    }
  };

  return (
    <>
      <SEO
        title="Kontakt - Projektowanie terenów zieleni w Poznaniu i okolicach"
        description="Greener zajmuje się projektowaniem terenów zieleni i przestrzeni publicznych, zakładaniem oraz pielęgnacją ogrodów na terenie Poznania i okolic. Sprawdź!"
      />
      <Hero image={data.singlePage.image} text={data.singlePage.description} />
      <ColorWrapper>
        <CompanyName>
          Greener ARCHITEKTURA ZIELENI Marcin Weber Renata Weber S.C.
        </CompanyName>
        <StyledWrapper>
          <WrapperAlignRight>
            <p>ul. Miodowa 6c/8</p>
            <p>62-200 Gniezno</p>
            <SmallerP>NIP: 783 174 85 55</SmallerP>
            <SmallerP>REGON: 365 577 521</SmallerP>
          </WrapperAlignRight>
          <WrapperAlignLeft>
            <p>
              <a href="tel:+48 576 982 490">+48 576 982 490</a>
            </p>
            <p>
              <a href="mailto:greener@greener-ak.pl">greener@greener-ak.pl</a>
            </p>
            <SocialMenu />
          </WrapperAlignLeft>
        </StyledWrapper>
      </ColorWrapper>
      <ContactFormCom />
    </>
  );
}

export const query = graphql`
  query SinglePageContactQuery {
    singlePage: sanitySinglepage(name: { eq: "Kontakt" }) {
      id
      name
      description
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;
